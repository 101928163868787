<template>
  <div style="display: flex">
    <el-select  v-model="caddress.sheng" style="flex: 1;" placeholder="请选择省份" @change="getCityData">
      <el-option
        v-for="item in addressArray"
        :key="item.code"
        :label="item.name"
        :value="item.name+','+item.code"
      >
      </el-option>
    </el-select>
    <el-select  v-model="caddress.shi" style="flex: 1;margin-left: 10px" placeholder="请选择市区" @change="getAreaData">
      <el-option
        v-for="item in cityArray"
        :key="item.code"
        :label="item.name"
        :value="item.name+','+item.code">
      </el-option>
    </el-select>
    <el-select  v-model="caddress.qu" style="flex: 1;margin-left: 10px" placeholder="请选择县" @change="getStreetData">
      <el-option
        v-for="item in areaArray"
        :key="item.code"
        :label="item.name"
        :value="item.name+','+item.code">
      </el-option>
    </el-select>
    <el-select  v-model="caddress.jd" style="flex: 1;margin-left: 10px" placeholder="请选择街道" @change="onStreetChanged">
      <el-option
        v-for="item in streetArray"
        :key="item.code"
        :label="item.name"
        :value="item.name+','+item.code">
      </el-option>
    </el-select>
  </div>
</template>

<script>

  // 使用说明：v-model时，必须传带有带有省，市，区，街道拼音的字段
  
  // 导入数据
  import dataJson from '@/assets/json/data.json'
  export default {
    name: "liAreaSelect",
    //通过 model 选项配置子组件接收的 prop 名以及派发的事件名
    model: {
      prop: 'caddress',
      event: 'change',
    },
    props: {
      caddress: {
        type: Object,
      },
    },
    data() {
      return {
        areaJson:dataJson,
        addressArray: [],//所有数据
        cityArray: [],
        areaArray: [],
        streetArray: []
      }
    },
    created() {
      this.getAddressData();
    },
    methods: {
      getAddressData() {
        this.addressArray = this.areaJson;
        //默认值赋值获取城市数组
        if(this.caddress.sheng){
          for (let ad of this.addressArray) {
            if (ad.name === this.caddress.sheng.split(',')[0]) {
              this.cityArray = ad.childs;
              //默认赋值获取区域数组
              if(this.caddress.shi){
                for (let area of this.cityArray) {
                  if (area.name === this.caddress.shi.split(',')[0]) {
                    this.areaArray = area.childs;
                    // 默认赋值获取街道数组
                    if(this.caddress.qu){
                      for(let street of this.areaArray){
                        if(street.name === this.caddress.qu.split(',')[0]){
                          this.streetArray = street.childs;
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        console.log("this.cityArray",this.cityArray);
        console.log("this.areaArray",this.areaArray);
        console.log("this.streetArray",this.streetArray);
      },
      //选择省份
      getCityData(val) {
        // console.log("AAA",this.caddress);
        //清空市、区、街道
        this.caddress.shi='';
        this.caddress.qu='';
        this.caddress.jd='';
        this.$emit('change', this.caddress);//发送改变
        for (let ad of this.addressArray) {
          if (ad.name === val.split(',')[0]) {
            this.cityArray = ad.childs;
            return
          }
        }
      },
      getAreaData(val) {
        // console.log("BBB",this.caddress);
        // 清空区、街道
        this.caddress.qu='';
        this.caddress.jd='';
        this.$emit('change', this.caddress);//发送改变
        for (let area of this.cityArray) {
          if (area.name === val.split(',')[0]) {
            this.areaArray = area.childs;
            return
          }
        }
      },
      getStreetData(val){
        // console.log("CCC",this.caddress);
        // 清空街道
        this.caddress.jd='';
        this.$emit('change', this.caddress);//发送改变
        for (let street of this.areaArray) {
          if (street.name === val.split(',')[0]) {
            this.streetArray = street.childs;
            return
          }
        }
      },
      // 街道数据变动后
      onStreetChanged(){
        console.log("DDD",this.caddress);
        this.$emit('change', this.caddress);//发送改变
      }
    }
  }
</script>

<style scoped>

</style>
